.toggle-layout-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;

    .toggle-layout-btn {
        margin-left: 0.1rem;
    }
}

.widget {
    flex: 1;
    margin: 0.5rem;
}

.toggle-layout-container {
    display: flex;
    flex: 1;

    &.flex-horizontal {
        flex-direction: row;
        justify-content: space-between;

        .widget {
            width: 50%;
        }
    }
    &.flex-vertical {
        flex-direction: column;

        .widget {
            width: 100%;
        }
    }
}
