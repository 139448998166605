.sidebar-panel {
    .navbar {
        padding: 0;
    }

    .container-fluid {
        width: 100%;
    }

    .offcanvas-header {
        width: 100%;
        padding: 0.63rem 1rem;
        // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .btn-menu-close {
        display: none;
        width: auto;
        color: #fff;
        border: none;
        background: none;

        .bi {
            width: auto;
            font-size: inherit;
        }
    }

    .navbar-brand {
        margin: 0;
    }

    .offcanvas-title {
        font-size: inherit;
        font-weight: 500;
        color: #fff;
        margin: 0;
    }

    .container-fluid {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .nav {
        width: 100%;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }

    .nav-link {
        color: #fff;
        font-size: 0.9rem;
        text-transform: uppercase;
        text-align: left;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 0.8rem;
        margin-bottom: 0.3rem;
    }
    .nav-link.active {
        background: rgba(255, 255, 255, 0.23);
    }
    .nav-link:hover,
    .nav-link:focus {
        background: rgba(255, 255, 255, 0.13);
    }

    .bi {
        width: 2.2rem;
        font-size: 1.5rem;
        display: inline-block;
        text-align: left;
    }

    .nav-extra-container {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .nav {
            margin-top: 0;
            margin-bottom: 0;
        }

        .nav-extra-title {
            font-size: 0.9rem;
            color: #fff;
            text-transform: uppercase;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .nav-link {
            font-size: 1rem;
            text-transform: none;
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: 1080px) {
        .btn-menu-close {
            display: inherit;
        }
    }
}
