.pagination {
    .page-item {
        &.active {
            .page-link {
                cursor: default;
            }
        }
        .page-link {
            cursor: pointer;
        }
    }
}


.pagination-spinner-wrapper {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    padding: 1rem;
    border-radius: 0.5rem;
    background: rgba(0,0,0,0.3);
}
