.master-header {
    //color: #888888;
    color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: linear-gradient(to right, #6f42c1, #0d6efd);
    
    h2 {
        font-size: 1.25rem;
        color: inherit;
        margin-bottom: 0;
    }

    .navbar {
        width: 100%;
        padding: 0;
    }
    .container-fluid {
        padding-right: 0
    }

    .navbar-brand {
        color: #fff;
    }

    .nav-link {
        color: inherit;
        //padding: 0.5rem 0.8rem;
        padding: 0 0.8rem;
        font-size: 0.9rem;
        text-transform: uppercase;
        text-align: left;
        justify-content: flex-start;
        align-items: center;

        .bi {
            width: 2rem;
            font-size: 1.5rem;
            display: inline-block;
            //text-align: left;
        }
    }
    .nav-link.active {
        background: rgba(255, 255, 255, 0.23);
    }
    .nav-link:hover,
    .nav-link:focus {
        background: rgba(255, 255, 255, 0.13);
    }
    .nav-item {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .dropdown-toggle::after {
        content: none;
    }
    .dropdown-menu[data-bs-popper] {
        left: auto;
        right: 0;
    }
    
    .btn-menu {
        font-size: 1.4rem;
        display: none;
        padding: 0.1rem 0.4rem;
    }
    
    // .arachnid-header-logo {
    //     float: left;
    //     width: 121px;
    //     margin-right: 10px;
    // }
    
    @media only screen and (max-width: 768px) {
        .btn-menu {
            display: inherit;
        }

        .main-nav {
            display: none;
        }
    }
}

.offcanvas-header{
    .navbar-brand {
        color: #fff;
    }
}
