.nav-tabs .nav-link.active, 
.nav-tabs .nav-item.show .nav-link {
    color: #0d6efd;
    color: #000;
    border-color: transparent;
    background: #dee2e6;
}

.nav-tabs .nav-link {
    color: #000;
}

.tab-content {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    background: #dee2e6;
}
