body {
    min-width: 320px;
    background: #F3F6FE;
    //background: rgba(203, 203, 210, 0.15)
}

.sidebar-panel {
    position: fixed;
    top: 0;
    left: -260px;
    bottom: 0;
    width: 260px;
    overflow-y: auto;
    webkit-overflow-scrolling: touch;
    background: linear-gradient(to bottom, #6f42c1, #0d6efd);
}


.master-main {
    position: relative;
    //min-height: calc(100% - 115px);
    //padding: 6.6rem 1.5rem 4.5rem 1.5rem;
    padding: 1rem;
    margin-top: 4.8rem;
    margin-bottom: 3.7rem;

    > .container {
        padding-left: 0;
        padding-right: 0;
    }
}

.master-header,
.master-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.1rem 1.5rem;
    background: rrgba(203, 203, 210, 0.15);
}


.main-nav {
    &.detail_subnav {
        top: 76px;
        text-align: center;
        margin-left: -1rem;
        margin-right: -1rem;
        background: #F7F7F8;
        background: rgba(247, 247, 248, 0.5);
        backdrop-filter: blur(10px);
        overflow-x: auto;
        webkit-overflow-scrolling: touch;
        z-index: 1019;

        .nav {
            min-width: 500px;

            .nav-link {
                &.active {
                    color: var(--bs-nav-link-color);
                    background: none;
                }
            }

            .btn {
                &.active {
                    color: #fff;
                    background: #6c757d;
                }
            }
        }
    }
}



@media (max-width: 1080px) {
    .sidebar-panel {
        left: -260px;
    }

    .main-panel {
        float: none;
        width: 100%
    }
}

// @media (max-width: 480px) {
//     .main-nav {
//         &.detail_subnav {
//             .nav {
//                 overflow-x: scroll;
//                 webkit-overflow-scrolling: touch;

//             }
//         }
//     }
// }
