.spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-grow {
        margin: 0 0.1rem;
    }
}

.pagination-spinner-wrapper {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // min-height: 581px;
    text-align: center;
}

// Changing the color of the spinner in the dashbaord small cards.
#spinnerOverallNoticeCount,
#spinnerNoticesThisMonth,
#spinnerSightingsThisMonth,
#spinnerNoticeRateChangeThisMonth,
#spinnerSightingsRateChangeThisMonth,
#spinnerTotalSightings {
    .spinner-border {
        color: #fff!important;
    }
}

#spinnerTopOffenders {
    text-align: center;
}



.dataLoadingWidget {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //z-index: 2000;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to right, #6f42c1, #0d6efd);
    z-index: 99999;
}
