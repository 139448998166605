.widget-form {
    padding: 1.5rem;
}

.widget {
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
    
    .widget-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    .widget-toolbar {
        & .dropdown-toggle::after {
            display: none;
        }
    }
    .widget-link {
        font-size: 0.7rem;
    }

    &.widget-overall-notice {
        color: #fff;
        background: rgba(255, 99, 132, 0.9);

        .text-body-secondary {
            color: #fff!important;
        }
    }
    &.widget-sightings-this-month {
        color: #fff;
        background: rgba(75, 192, 192, 0.9);

        .text-body-secondary {
            color: #fff!important;
        }
    }
    &.widget-sightings-rate-change-this-month {
        color: #fff;
        background: rgba(255, 159, 64, 0.9);

        .text-body-secondary {
            color: #fff!important;
        }
    }
    &.widget-notices-this-month {
        color: #fff;
        background: rgba(75, 192, 192, 0.9);

        .text-body-secondary {
            color: #fff!important;
        }
    }
    &.widget-notice-rate-change-this-month {
        color: #fff;
        background: rgba(255, 159, 64, 0.9);

        .text-body-secondary {
            color: #fff!important;
        }
    }
    &.widget-total-sightings {
        color: #fff;
        background: rgba(153, 102, 255, 0.9);

        .text-body-secondary {
            color: #fff!important;
        }
    }
}

// @media only screen and (max-width: 1500px) {
//     .dataDashboard {
//         &.toggle-layout-container {
//             display: block;
//         }
//     }
// }

@media only screen and (max-width: 1200px) {
    .dataDashboard {
        &.toggle-layout-container {
            &.small-cards {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                padding: 0.5rem;

                .widget {
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 515px) {
    .dataDashboard {
        &.toggle-layout-container {
            &.small-cards {
                display: block;
    
                .widget {
                    margin-bottom: 1rem;
                }
                .widget:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
