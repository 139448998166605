.master-footer {
    font-size: 0.9rem;
    color: #fff;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    background: linear-gradient(to right, #6f42c1, #0d6efd);
    display: block;
    text-align: center;

    .navbar {
        padding: 0;
    }
    .container-fluid {
        padding: 0;
    }

    .nav-item {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }

        .nav-link { 
            color: #fff;
        }
    }

    .nav-link {
        padding: 0;
    }

    p {
        color: inherit;
        margin-bottom: 0;
    }

    @media only screen and (max-width: 760px) {
        display: block;
        text-align: center;

        .nav {
            display: block;
        }
        
        .container-fluid {
            display: block;
            margin-bottom: 0.5rem;
        }

        .nav-item {
            display: inline-block;
        }
    }
}
