@media print {
    body {
        font-size: 0.8rem;
    }

    .container {
        max-width: none;
    }

    .toggle-layout-container {
        max-width: 100%;
        display: block;
    }

    .main-panel {
        float: none;
        width: 100%;
        max-height: none;
        min-height: auto;
        height: auto;
        
    }
    .master-main {
        min-height: auto;
        padding: 0;
        margin: 0;
    }
    .sidebar-panel {
       display: none!important; 
    }

    .widget {
        display: none;
        width: 100%;
        border: none;
        padding: 0;
        margin: 0;
        flex: none;
        background: none;
        box-shadow: none!important;
        
        &.print {
            display: block!important;
        }
    }
    .modal-header,
    .modal-footer {
        padding: 0;
    }
    .modal-header {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .modal-body {
        padding: 0;
    }
    .modal-content {
        border: none;
    }

    .modal-dialog {
        max-width: none;
        min-height: auto;
        margin: 0;
        padding: 0;
        transform: none;
    }

    canvas {
        width: auto!important;
        height: auto!important;
        margin: auto;
    }
    // #monthlyChart {
    //     width: 740px!important;
    //     height: auto!important;
    // }
    

    // .table {
    //     th:first-child,
    //     td:first-child {
    //         width: 15%!important;
    //     }
    //     th:nth-child(2),
    //     td:nth-child(2) {
    //         width: 15%!important;
    //     }
    //     th:nth-child(3),
    //     td:nth-child(3) {
    //         width: 20%!important;
    //     }
    //     th:nth-child(4),
    //     td:nth-child(4) {
    //         width: 30%!important;
    //     }
    //     th:nth-child(5),
    //     td:nth-child(5) {
    //         width: 5%!important;
    //     }
    //     th:last-child,
    //     th:last-child {
    //         width: 15%!important;
    //     }
    // }

    .modal {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        width: auto;
        height: auto;
        overflow: visible;
        overflow-x: visible;
        overflow-y: visible;
        padding: 0;
        margin: 0;
        //opacity: 1;
    }
    .table-responsive {
        overflow: visible;
        overflow-x: visible;
        overflow-y: visible;
    }

    .btn-warning {
        padding: 0;
        border: none;
        background: none;
    }

    #espNoticesTabular {
        .table {
            button {
                padding: 0;
                border: none;
                background: none;
                color: black;
                display: inline-block;

                // span {
                //     display: inline-block!important;

                //     &::after {
                //         content: ",";
                        
                //     }
                // }

                .bi {
                    display: none;
                }

                // Target the last button inside the TD
                // &:nth-last-of-type(1) {
                //     span {
                //         &::after {
                //             content: none;
                //         }
                //     }
                // }
            }
        }
    }
}
  