.modal-dialog {
    max-width: 1280px;
}

.modal-toolbar {
    & .dropdown-toggle::after {
        display: none;
    }
}

.modal-header,
.modal-footer {
    border: none;
}

#modalGlobalFilters,
#modalGlobalFiltersOutOfDateTRange {
    .modal-dialog {
        max-width: 500px;
    }
}
