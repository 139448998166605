.container {
  max-width: 1770px;
}

@media only screen and (max-width: 1400px) {
  .container {
    max-width: 100%;
  }
}

// @media only screen and (max-width: 1080px) {
//     .container {
//         max-width: 100%;
//     }
// }
