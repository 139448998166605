.qs-datepicker-container {
    min-width: 250px;
    font-family: inherit;
    z-index: 10;
}

.qs-day {
    font-size: 0.9rem;
    text-align: center;
}

.qs-controls {
    padding: 0.5rem;
    background: #dee2e6;
}
.qs-square {
    color: $gray-600;
}

.qs-current {
    color: #fff;
    text-decoration: none;
    background: rgba(255, 159, 64, 0.9);

    &.qs-empty {
        background: none;
    }
}

.date-box {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    //border-radius: 50%;

    &.blue {
        background: #0d6efd;
    }
    &.orange {
        background: rgba(255, 159, 64, 0.9);
    }
}


#searchDateToIcon,
#searchDateFromIcon {
    cursor: pointer;
}

.qs-active, 
.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover  {
    color: #fff;
    background: #0d6efd;
}
.qs-overlay {
    color: #000;
    background: #dee2e6;
}

.qs-active {
    &.qs-empty {
        background: none;
    }
}

.qs-overlay .qs-submit {
    color: #fff;
    background: #0d6efd;
}


.qs-overlay .qs-overlay-year {
    color: #000;
    border-bottom-color: #000;
}
