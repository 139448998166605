.filter-chip{
  font-size: 0.85rem;
  //font-weight: 600;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
}

.text-decoration-none-chip {
  max-width: 400px;
}

.chip {
  .bi {
    font-size: 0.9rem;
  }
}

#selectedEspType {
  padding-top: 0.5rem;
}
