canvas {
    max-width: 100%;
    height: auto;
}

.chart-responsive {
    width: 100%;
    //height: 650px; // Default height
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.canvas-container-for-tablet {
    width: 100%;
    height: 650px;
}


@media only screen and (max-width: 850px) {
    .chart-responsive {
        height: auto;
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .canvas-container-for-tablet {
        width: 850px;
    }
}
