.table-responsive {
    &.table-blur {
        filter: blur(5px);
    }

    thead {
        th {
            font-size: 0.8rem;
            font-weight: 600;
            text-transform: uppercase;
            color: #9a9a9a;
            //padding-top: 1.3rem;
            //padding-bottom: 1.1rem;
        }
    }
}

// #sightingsModal {
//     thead {
//         th:nth-child(5) {
//             min-width: 100px;
//         }
//         th:nth-child(4),
//         th:last-child {
//             min-width: 160px;
//         }
//     }
// }

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 200px;
  //overflow-y: scroll;
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.suggestions-list li {
  padding: 0.5rem;
  cursor: pointer;
}

.suggestions-list li:hover {
  color: #fff;
  background-color: #0d6efd;
}
